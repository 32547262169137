import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'

const Symptom = ({ data }) => (
  <Layout pageTitle='Vanliga symptom'>
    <oma-grid-row>
      <div className='section'>
        <h1 className='heading page__heading'>
          Vanliga symptom som en naprapat kan hjälpa till med
        </h1>
        <div className='section__columns'>
          <h2>Nacke</h2>
          <p className='section__text'>
            Spänningshuvudvärk orsakas ofta av spända muskler i nacke axlar om
            man belastar dem under längre tid utan vila. Kan ge en tryckande
            känsla i huvudet och pirra fram mot käke, panna tinningar och ibland
            stråla ned mot axel/överarm. Nackspärr är en plötsligt uppkommen
            smärta i nacken som gör att det är svårt att vrida på huvudet åt
            oftast ena hållet men i vissa fall båda hållen. Man vaknar ofta med
            lite ont i nacken som ökar på under dagen. Yrsel kan även det
            orsakas av spända muskler. Vid yrsel gäller det att utesluta
            allvarliga sjukdomar men spänningsyrsel och kristallyrsel är något
            vi kan behandla.
          </p>
          <h2>Arm</h2>
          <p className='section__text'>
            Värk eller utstrålning i armen kan komma från nacken eller vara
            lokala besvär. Flera muskler från nacken och skuldran kan stråla ut
            i armen. Tennis/golf/musarmbåge är en värk som sitter på insidan
            eller utsidan av armbågen och kommer ofta av en repetitiv belastning
            där det gäller att ändra belastningen och även behandla och träna
            upp armbågen så att besvären inte kommer tillbaka.
          </p>
          <h2>Ländrygg</h2>
          <p className='section__text'>
            Ryggskott kommer ofta av en snabb okontrollerad rörelse t.ex. halka
            på en isfläck eller göra ett lyft samtidigt som man vrider sig. Ger
            ofta en lokal värk i länden och kan stråla ut i ena benet.
            Stelhet/trötthet i ländryggen orsakas ofta av spända muskler och
            inaktivitet där det gäller att komma igång med stretching och
            träning samt behandla stelheten. Ischias är en bred benämning på
            något som strålar ut i ena benet. Äkta ischias är ett tryck på
            ischiasnerven och strålar ut till vaden, vristen och det är inte
            ovanligt att det även strålar ut i foten. Kan bero på diskbråck
            eller annan skada i ryggen. Det vanligaste är dock falsk ischias
            vilket inte är en åverkan på ischiasnerven utan kommer från muskel
            eller led som strålar ut i benet. Här gäller det att ställa en
            noggrann diagnos så att man vet om det är något vi kan behandla
            eller om man behöver skicka vidare för vidare undersökning.
          </p>
          <h2>Ben, knä och fot</h2>
          <p className='section__text'>
            Knän och fötter drabbas ofta av överbelastningsskador om man ökar på
            träningen för snabbt eller har en felaktig teknik när du tränar.
          </p>
          <GatsbyImage
            image={data.treatment1.childImageSharp.gatsbyImageData}
            className='symptom-image'
          />
          <GatsbyImage
            image={data.treatment2.childImageSharp.gatsbyImageData}
            className='symptom-image'
          />
          <GatsbyImage
            image={data.treatment3.childImageSharp.gatsbyImageData}
            className='symptom-image'
          />
        </div>
      </div>
    </oma-grid-row>
  </Layout>
)

export const query = graphql`
  {
    treatment1: file(relativePath: { eq: "treatment1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 400, quality: 85, layout: FIXED)
      }
    }
    treatment2: file(relativePath: { eq: "treatment2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 400, quality: 85, layout: FIXED)
      }
    }
    treatment3: file(relativePath: { eq: "treatment3.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 400, quality: 85, layout: FIXED)
      }
    }
  }
`

export default Symptom
